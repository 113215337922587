export default {
  text: 'black',
  primary: '#347f3a',
  secondary: '#f9ad19',
  tertiary: '#cc2127',
  quaternary: '#fa5235',
  background: 'white',
  backgroundSecondary: '#33cbc9',
  light: '#FFF',
  dark: '#black'
}
