export default {
  heading: 'Bevan',
  subheading: 'Satisfy, sans-serif',
  body: 'Comfortaa, sans-serif',
  body2: 'Open Sans, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Billy, sans-serif',
  googleFonts: ['Open Sans:100,200,300,400,500,600,700,800,900', 'Comfortaa', 'Satisfy', 'Bevan', 'Bangers'],
  customFamilies: ['Billy', 'Blowhole', 'Supersonic Rocketship'],
  customUrls: [
    'https://www.gonation.biz/fonts/billy/billy.css',
    'https://www.gonation.biz/fonts/blowhole/blowhole.css',
    'https://www.gonation.biz/fonts/supersonic-rocketship/supersonic-rocketship.css'
  ]
}
