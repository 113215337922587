export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================
  locationsPage: {
    '.locationsContainer': {
      margin: ['0.25rem 0rem', '', '0.5rem 0.25rem']
    },
    '.logo': {
      height: 'unset',
      position: 'static',
      transform: 'unset',
      padding: ['0.5rem', '', '1rem'],
      backgroundColor: 'black',
      img: {
        maxHeight: ['60px', '75px']
      }
    },
    '.location': {
      margin: '0.25rem 0.5rem'
    },
    // '.CTAButton': {
    //   display: 'none'
    // },
    '.locationTitle': {
      variant: 'customVariants.title',
      textShadow: '3px 3px 0px #026747',
      justifyContent: 'center',
      width: '100%'
    },

    '.CTAButton': {
      variant: 'buttons.secondary'
    },

    '.enterSiteButton': {
      variant: 'buttons.primary'
    }
    // '.location1': {
    //   display: 'none'
    // }
  },

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.navItemDropdownMenu': {
      '.navItem': {
        a: {
          color: 'white',
          ':hover': {
            color: 'white!important',
            textDecoration: 'underline!important'
          }
        }
      }
    },
    '.container': {
      padding: ['0.5rem 1rem', '0.5rem 1rem'],
      position: 'absolute',
      backgroundColor: 'background',
      top: '0rem',
      '.smallNavMenu': {
        '.navItem': {
          color: 'text',
          margin: '0rem 0.5rem',
          a: {
            fontSize: '1rem',
            ':hover': {
              color: 'text',
              textDecoration: 'underline'
            }
          }
        },
        '.react-reveal': {
          opacity: '1'
        }
      }
    },
    '.containerScrolled': {
      border: 'none',
      padding: '0.5rem 1rem',
      backgroundColor: 'background',
      position: 'fixed',
      right: '0rem',
      top: '0rem',
      left: 'unset',
      '.smallNavMenu': {
        '.navItem': {
          color: 'text',
          a: {
            fontSize: '1rem',
            ':hover': {
              color: 'text',
              textDecoration: 'underline'
            }
          }
        },
        '.react-reveal': {
          opacity: '1'
        }
      }
    },

    // top level nav styles

    '.logoLocationContainer': {
      padding: '0rem',
      position: 'static',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0rem auto 0rem 0rem',
      '.locationSwitcher': {
        margin: 'auto auto auto 0.5rem',
        justifyContent: 'center',
        '.locationSwitcherV1': {
          position: 'static',
          padding: '0rem',
          '> button': {
            padding: '0rem 0.25rem',
            background: 'none',
            backgroundColor: 'primary',
            borderRadius: '5px',
            color: 'white',
            fontSize: 12
          },
          '.popUp': {
            transform: 'unset'
          }
        }
      }
    },
    '.logo': {
      a: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      },
      img: {
        maxHeight: ['60px', '65px', '75px', '80px', '85px'],
        filter: 'unset'
      }
    },
    '.logoScrolled': {
      img: {
        maxHeight: ['50px', '60px', '60px', '60px', '60px'],
        filter: 'unset'
      }
    },

    // hamburger
    '.hamburger': {
      padding: '0.75rem',
      border: 'none',
      display: ['', '', ''],
      order: '5',
      margin: '0rem',
      '> div': {
        backgroundColor: 'text'
      }
    },

    '.hamburgerOpen': {
      borderColor: 'white',
      borderRadius: '100px',
      padding: '0.75rem',
      borderColor: 'primary',
      backgroundColor: 'rgba(255,255,255, 0.6)',
      display: ['', '', ''],
      order: '5',
      margin: '0rem',
      '> div': {
        backgroundColor: 'light'
      }
    },

    '.socialIconsContainerDesktop': {
      margin: ['0rem', '', '', '0rem'],
      display: 'flex',
      order: '4',
      svg: {
        border: 'solid 1px',
        borderColor: 'secondary',
        width: ['30px', '', '40px'],
        height: ['30px', '', '40px'],
        padding: ['0.25rem', '', '0.5rem'],
        borderRadius: '100px',
        backgroundColor: 'rgba(255, 255, 255, 0.35)',
        path: {
          fill: 'secondary'
        }
      }
    },

    '.smallNavMenu': {
      order: '3',
      '.navItemDropdownMenu': {
        backgroundColor: 'black'
      }
    },
    '.navItem': {
      a: {
        fontFamily: 'heading'
      }
    },

    // nav menu styles
    '.navMenu': {
      '.navMenuLogo': {
        display: 'none'
      }
    },

    '.navMenuOpen': {
      justifyContent: 'flex-start',
      alignItems: ['', '', '', 'flex-end'],
      padding: ['15vh 2rem 2rem', '', '', '15vh 4rem 4rem'],
      width: '80%',
      maxWidth: '500px',
      backgroundColor: 'primary',
      // backgroundImage:
      //   'url(https://res.cloudinary.com/gonation/image/upload/v1678804377/sites/tacos-al-azteca/taco2.png)',
      // backgroundRepeat: 'no-repeat',
      // backgroundPosition: '25% 95%',
      '.navMenuLogo': {
        display: 'none'
      },

      '.navItem': {
        a: {
          color: 'light',
          textAlign: ['', '', '', 'right'],
          display: 'flex',
          ':hover': {
            textDecoration: 'underline'
          }
        }
      },

      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },

      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right'],
        color: 'white'
      },

      '.seperatorLine, .phoneSocialContainer': {
        display: 'none'
      },

      // widgets

      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      },

      '.socialIconsContainer': {
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
          svg: {
            path: {
              fill: 'white'
            }
          }
        }
      },

      '.phoneContainer': {
        display: 'flex',
        border: 'none',
        margin: '0rem 1rem',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        a: {
          backgroundColor: 'primary',
          padding: '0.5rem',
          color: 'light',
          textAlign: 'center',
          margin: '0.5rem 0rem'
        }
      }
    },

    '.navBlock': {
      right: 'unset',
      top: '200vh'
    }
  },

  footer: {
    backgroundColor: 'primary',
    borderTop: 'solid 5px',
    borderColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['0.5rem 0.5rem 6rem', '', '0.5rem 3rem'],
    '.image': {
      // filter: 'brightness(0) invert(1)',
      display: 'none'
    },
    '.multiButtonContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0rem',
      a: {
        width: 'fit-content',
        fontFamily: 'heading'
      }
    },
    '.contactDetails-container': {
      display: 'none'
    },
    '.copyright': {
      width: '50%',
      color: 'white',
      textAlign: 'center'
    },
    '.socialIconsContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '1rem',
      a: {
        svg: {
          width: '40px',
          height: '40px',
          padding: '0.5rem',
          border: 'solid 1px white',
          borderRadius: '100px',
          ':hover': {
            backgroundColor: 'grey'
          }
        },
        margin: '0.5rem'
      }
    },
    '.gonationLogo': {
      width: '50%'
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['1.25rem', '1.25rem', '1.5rem', '1.75rem', '2rem'],
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'inherit',
    marginBottom: '1.5rem',
    textTransform: 'uppercase',
    fontFamily: 'heading'
  },
  subtitle: {
    width: 'fit-content',
    textAlign: 'inherit',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.5rem', '1.75rem', '2rem', '2rem'],
    marginBottom: '1.5rem',
    border: 'none',
    color: 'inherit',
    fontFamily: 'subheading'
  },
  text: {
    p: {
      color: 'inherit',
      lineHeight: '2',
      fontSize: 'inherit',
      textAlign: 'center'
    }
  },

  sideBySide1: {
    padding: ['2rem 1rem', '', '3rem 1rem 4rem'],
    margin: ['', '', '0rem 0rem 0rem'],
    '.lazyload-wrapper': {
      width: ['100%', '', '49%', '49%'],
      minHeight: ['', '', '50vh'],
      maxHeight: ['', '', '70vh'],
      justifyContent: 'flex-start',
      position: 'relative',
      img: { zIndex: '1', position: 'relative' },
      '::before': {
        content: "''",
        width: '100%',
        height: '100%',
        backgroundColor: 'primary',
        top: '1rem',
        left: '1rem',
        position: 'absolute'
      }
    },

    '.content': {
      width: ['100%', '', '50%', '50%'],
      position: 'relative',
      alignItems: 'flex-start',
      margin: ['1rem 0rem', '', '0rem'],
      padding: ['2rem 1rem', '2rem', '3rem', '3rem', '4rem'],
      height: 'fit-content',
      position: 'relative',
      // bottom: ['', '', ''],
      borderRadius: '50px',
      alignSelf: 'center'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'secondary'
    },
    '.text': {
      variant: 'customVariants.text',
      p: {
        textAlign: 'left'
      }
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      marginBottom: '0rem'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    paddingLeft: ['', '', '4rem'],
    py: '3rem',
    'div.content': {
      left: ['', '', '-4rem'],
      backgroundColor: 'quaternary'
    }
  },

  centerBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '90vh',
    color: 'white',
    textAlign: 'center',
    padding: ['6rem 1rem', '8rem 1rem'],
    '.section': {
      maxWidth: 'unset',
      width: '95%'
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'white',
      fontSize: ['2rem', '2.5rem', '3rem', '4.5rem', '6rem'],
      textShadow: '6px 6px 0px #026747'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'secondary',
      fontSize: ['2rem', '2.5rem', '3rem', '3.5rem']
    },

    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      maxWidth: '767px'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  centerBlock2: {
    variant: 'customVariants.centerBlock',
    'h2.title': {
      textShadow: '6px 6px 0px #f30308'
    }
  },

  boxes: {
    '.box': {
      position: 'relative',
      img: {
        borderRight: '8px solid',
        borderBottom: '8px solid',
        borderColor: 'secondary',
        height: '250px',
        objectFit: 'cover'
      }
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageTag1: {
    marginTop: ['78px', '', '100px'],
    height: ['calc(100vh - 78px)', '', 'calc(100vh - 100px)'],
    '.slick-initialized .slick-slide > div, .slick-slider, .slick-list, .slick-track, .slick-slide > div, .slick-slide img':
      {
        height: 'calc(100vh - 78px)'
      },

    '.hero_content_container': {
      left: ['50%'],
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      alignItems: 'center',
      width: '95%',
      maxWidth: '100%',
      marginLeft: '0rem'
    },

    '.title': {
      variant: 'customVariants.title',
      color: 'white',
      fontSize: ['2rem', '2.5rem', '3rem', '4rem', '4.25rem'],
      textShadow: ['3px 3px 0px #026747', '', '6px 6px 0px #026747'],
      fontWeight: 'bold'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'secondary',
      fontSize: ['2rem', '2.5rem', '3rem', '3.5rem'],
      textShadow: '1px 1px 20px black',
      width: '95%',
      maxWidth: '800px',
      '::before': {
        content: "''",
        // backgroundColor: 'secondary',
        width: '10%',
        height: '1px',
        border: '3px dotted',
        borderColor: 'secondary'
      },
      '::after': {
        content: "''",
        // backgroundColor: 'secondary',
        width: '10%',
        height: '1px',
        border: '3px dotted',
        borderColor: 'secondary'
      }
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    },

    '.slick-arrow': {
      height: '60px',
      width: '60px',
      color: '#3e86bf !important',
      filter: 'drop-shadow(0px 0px 6px rgba(0,0,0,0.8))'
    },

    '.slick-prev': {
      left: '1rem'
    },

    '.slick-next': {
      right: '1rem'
    },

    '.slick-dots': {
      li: {
        button: {
          '::before': {
            color: '#327d8adb',
            fontSize: '1.5rem'
          }
        }
      }
    },
    '.slick-dots li.slick-active button:before': {
      color: 'primary'
    }
  },

  homepageHeroShout: {
    display: 'none'
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageBoxes: {
    variant: 'customVariants.boxes'
  },

  homepageMenu: {
    variant: 'customVariants.centerBlock'
  },

  homepageContact: {
    variant: 'customVariants.centerBlock'
  },

  homepageShout: {
    backgroundColor: 'background',
    margin: '0rem',
    padding: '0rem',
    '.title': {
      border: ['', '', '', 'solid 9px'],
      borderColor: ['', '', '', '#ffffff8a'],
      fontSize: ['1.2rem', '', '3rem']
    },
    '.text': {
      fontFamily: 'heading',
      fontSize: ['1.75rem', '', '2.5rem'],
      order: ['4', '', '3'],
      fontWeight: 'normal',
      padding: ['1rem', '', '1.5rem 2.5rem'],
      backgroundColor: ['secondary', '', '', 'secondary'],
      color: '#202020'
    },
    '.date': {
      backgroundColor: 'black',
      fontSize: '0.8rem',
      fontWeight: 'bold',
      writingMode: ['', '', '', 'tb'],
      p: '1rem 0.5rem'
    },
    '.imageContainer': {
      order: ['', '', '4']
    },
    '.shoutCTAS': {
      border: 'solid 8px',
      borderColor: 'background'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      variant: 'customVariants.title',
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  bio: {
    variant: 'customVariants.sideBySide1',
    marginTop: '0rem',
    backgroundColor: '#e9e9e2',
    padding: '5rem 2rem 0rem',
    'div.content': {
      width: ['', '', '60%']
    },

    'div.lazyload-wrapper': {
      overflow: 'hidden',
      width: ['', '', '40%'],
      img: {
        objectFit: 'contain',
        objectPosition: 'top'
      }
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  // menu: {},
  menu: {
    backgroundSize: '400px',
    backgroundRepeat: 'repeat',
    maxWidth: 'unset',
    '.allInContainerWrapper > .allInContainer > .menuContainer > .menuSectionTitle': {
      display: 'none'
    },

    '.menuSectionTitle': {
      variant: 'customVariants.title',
      fontSize: ['1.5rem', '1.75rem', '2.5rem', '2.5rem', '2.5rem'],
      textAlign: 'left',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '1rem 3rem',
      margin: '1rem 0rem 2rem',
      borderBottom: 'solid 3px',
      borderColor: 'tertiary'
    },

    '.menuSectionDescription': {
      fontWeight: '200',
      textAlign: 'center',
      maxWidth: ['100%', '75%'],
      margin: '0rem auto 2rem'
    },

    '.menuItemsContainer': {
      margin: ['0rem 1rem', '0rem 2rem']
    },

    '.menuItemContainerImgActive': {
      height: '100%',
      width: ['100%', '', 'calc(50% - (2rem * 2))'],

      '.menuItemInnerContainer': {
        flexDirection: ['column', '', 'row'],
        justifyContent: 'center',
        alignItems: 'flex-start',
        '.menuItemContentContainer': {
          width: ['100%', '', '', '70%'],
          '.menuItemName': {
            variant: 'customVariants.title',
            fontSize: ['1.25rem', '1.5rem', '1.5rem', '1.5rem', '1.5rem'],
            // width: ['60%', '75%'],
            textShadow: 'none',
            marginBottom: '0rem'
          },
          '.itemVariantsContainer': {
            display: 'flex',
            flexWrap: 'wrap'
          },
          '.variantsContainer': {
            display: 'flex',
            flexWrap: 'wrap',

            margin: '0.5rem 0.5rem 0.5rem 0rem'
            // width: ['33.3%'],
          },
          '.variantContainer': {
            display: 'flex',
            flexDirection: 'column',
            fontFamily: 'body2',
            backgroundColor: '#efefefba',
            padding: '0.75rem',
            color: 'secondary',
            borderRadius: '10px'
          },
          '.menuItemPriceLabel': {
            fontWeight: 'bold',
            fontSize: '0.8rem',
            color: 'secondary',
            marginBottom: '0.25rem'
          },
          '.variantContainerFloatTopRight': {
            fontSize: '1.2rem',
            color: 'secondary',
            fontWeight: 'bold',
            '.menuItemPriceVariants': {
              fontSize: '1.2rem',
              color: 'primary',
              fontWeight: 'bold'
            }
          },
          '.menuItemLabelOptions': {
            fontSize: '0.7rem',
            maxWidth: '350px'
          },
          '.menuItemPriceVariants': {
            fontSize: '0.7rem',
            maxWidth: '350px',
            color: 'secondary'
          },
          '.menuItemDescription': {
            width: '100%',
            // backgroundColor: 'white',
            opacity: '1',
            fontSize: '1rem',
            color: 'primary',
            fontFamily: 'body',
            lineHeight: '1.5',
            mt: '0.5rem'
          },
          '.menuItemPrice': {
            color: 'primary',
            fontSize: '1.2rem'
          }
        },

        '.menuItemImageContainer': {
          width: ['100%', '', '', '30%'],

          '.menuItemImg': {
            // objectPosition: 'top',
          }
        }
      }
    },
    '.menuItemPageContent': {
      '.itemVariantsContainer': {
        display: 'flex',
        flexWrap: 'wrap'
      },
      '.variantsContainer': {
        display: 'flex',
        flexWrap: 'wrap',
        flexGrow: '1',
        margin: '0.5rem 1rem 0.5rem 0rem'
        // width: ['33.3%'],
      },
      '.variantContainer': {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'body2',
        backgroundColor: '#efefefba',
        padding: '0.75rem',
        borderRadius: '10px'
      },
      '.menuItemPriceLabel': {
        fontWeight: 'bold',
        fontSize: '0.8rem',
        marginBottom: '0.25rem'
      },
      '.variantContainerFloatTopRight': {
        fontSize: ['1.25rem', '1.5rem', '2rem']
      },
      '.menuItemLabelOptions': {
        fontSize: '0.7rem',
        maxWidth: '350px'
      },
      '.menuItemPriceVariants': {
        fontSize: '0.7rem',
        maxWidth: '350px'
      },
      '.menuItemDescription': {
        width: '100%',
        backgroundColor: 'white',
        opacity: '1',
        lineHeight: '1.7',
        padding: '1rem 0.25rem'
      }
    },
    '.menuItemButton': {
      display: 'none'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    mt: '112px',
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.albumName': {
      display: 'none'
    },
    '.albumPhotos': {
      gridGap: '0rem'
    },
    '.albumImage': {
      // padding: '0rem',
      width: ['50%', '', '33.3%', '25%'],
      height: ['', '', '300px', '400px']
    },
    '.albumsCTAActive': {
      textDecoration: 'none',
      opacity: '1'
    },
    '.albumsCTA': {
      opacity: '0.8'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',

    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      maxWidth: '1000px',
      color: 'secondary'
    },
    '.text': {
      color: 'white'
    },
    backgroundPosition: 'center center',
    color: 'white',
    backgroundColor: 'secondary',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto'
    }
  },

  locationMap: {
    flexWrap: 'wrap',
    '::before': {
      content: "''",
      width: '100%',
      height: '121px',
      backgroundColor: 'primary'
    },
    order: '3',
    '.content_container': {
      padding: '0rem'
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: 'secondary',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.75rem', '1.75rem', '1.75rem', '1.75rem', '1.75rem'],
      alignItems: 'center',
      color: 'black'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.boxesHeading': {
      display: 'none'
    },
    '.textContent': {
      backgroundColor: 'lightgrey'
    },
    '.imageContainer': {
      backgroundColor: 'white',
      img: {
        objectFit: 'contain',
        padding: '1rem'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  }
}
